const getFilterQueryString = (filters: any) => {
  const filterString = filterToString(filters);
  if (filterString.length === 0) return '';
  return `?filter=${filterString}`;
};
export const getProviderLink = (provider: any, filters: any) => {
  return `/provider/${getProviderNameSlug(provider.name)}/${provider.slug}${provider.isCompany ? getFilterQueryString(filters) : ''}`;
};
export const getCompanyLink = (provider: any, filters: any) => {
  return `/provider/${getProviderNameSlug(provider.companyName)}/${provider.companySlug}${getFilterQueryString(filters)}`;
};

export const getProviderNameSlug = (name: string) =>
  name
    .replace(/ /g, '-')
    .toLowerCase()
    .replace(/[^a-zA-Z0-9-]/g, '');
