export const parseFilter = (filter: string) => {
  const filters: any = {};
  if (!filter) return {};
  filter.split(';').map((filter: string) => {
    const [key, value] = filter.split('=');
    if (key === 'services') {
      filters[key] = value ? value.split(',') : [];
    } else {
      filters[key] = value;
    }
  });
  return filters;
};

export const filterToString = (filters: any) => {
  const filterParts = [];
  for (const key in filters) {
    const value = filters[key];

    if (value === null || value === '') continue;
    if (value instanceof Array && value.length === 0) continue;

    filterParts.push(`${key}=${value}`);
  }
  return filterParts.join(';');
};

const filterNameMap: any = {
  offerIntensive: 'Offer Intensive',
  takeInsurance: 'Accept Insurance',
};

export const filtersToArray = (obj: any) => {
  let arr: string[] = [];
  for (const key in obj) {
    const value = obj[key];
    if (value instanceof Array) {
      arr = arr.concat(value);
    } else if (value === 'true') {
      arr.push(filterNameMap[key] ? filterNameMap[key] : key);
    } else arr.push(value);
  }
  return arr;
};
